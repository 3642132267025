import React, { Fragment } from 'react';


import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Router from './router/Router';
import {
  BrowserRouter,
} from "react-router-dom";



function App() {
  return (
    <Fragment>
          <BrowserRouter>
       
            <Navbar/>
            
              <Router/>

            <Footer/>

          </BrowserRouter>
    </Fragment>
  );
}

export default App;
