import React, { useEffect } from "react";

import { Helmet } from "react-helmet";
import Fade from "react-reveal/Fade";

const Services = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Services - Gurufc</title>
      </Helmet>

      <section className="wrapper bg-soft-primary">
        <div className="container py-md-5 py-3 text-center">
          <div className="row">
            <div className="col-xl-7 mx-auto mb-6">
              <h1 className="display-1 mb-3">Our Services</h1>
              <h3 className="mb-0">
                We are a creative company that focuses on establishing long-term
                relationships with customers.
              </h3>
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>

      <section className="wrapper bg-light">
        <div className="container py-4 py-md-15 text-center">
          <div className="row">
            <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h3 className="display-4 mb-10 px-xl-10">
                Guru Financial Corporation is providing a variety of services
                like;
              </h3>
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
          <div className="position-relative">
            <div className="row gx-md-5 gy-5 text-center">
              <div className="col-md-6 col-xl-3">
                <div className="card shadow-lg lift h-100">
                  <div className="card-body bg-soft-dark border">
                    <h4 className="mb-0">Asset Based Financing</h4>
                  </div>
                  {/*/.card-body */}
                </div>
                {/*/.card */}
              </div>
              {/*/column */}
              <div className="col-md-6 col-xl-3">
                <div className="card shadow-lg lift h-100">
                  <div className="card-body bg-soft-dark border">
                    <h4 className="mb-0">Sale Leaseback</h4>
                  </div>
                  {/*/.card-body */}
                </div>
                {/*/.card */}
              </div>
              {/*/column */}
              <div className="col-md-6 col-xl-3">
                <div className="card shadow-lg lift h-100">
                  <div className="card-body bg-soft-dark border">
                    <h4 className="mb-0">Factoring</h4>
                  </div>
                  {/*/.card-body */}
                </div>
                {/*/.card */}
              </div>
              {/*/column */}
              <div className="col-md-6 col-xl-3">
                <div className="card shadow-lg lift h-100">
                  <div className="card-body bg-soft-dark border">
                    <h4 className="mb-0">Portfolio financing</h4>
                  </div>
                  {/*/.card-body */}
                </div>
                {/*/.card */}
              </div>
              {/*/column */}
              <div className="col-md-6 col-xl-3">
                <div className="card shadow-lg lift h-100">
                  <div className="card-body bg-soft-dark border">
                    <h4 className="mb-0">
                      SBA <br /> Financing
                    </h4>
                  </div>
                  {/*/.card-body */}
                </div>
                {/*/.card */}
              </div>
              {/*/column */}
              <div className="col-md-6 col-xl-3">
                <div className="card shadow-lg lift h-100">
                  <div className="card-body bg-soft-dark border">
                    <h4 className="mb-0">
                      Construction <br /> Financing
                    </h4>
                  </div>
                  {/*/.card-body */}
                </div>
                {/*/.card */}
              </div>
              {/*/column */}
              <div className="col-md-6 col-xl-3">
                <div className="card shadow-lg lift h-100">
                  <div className="card-body bg-soft-dark border">
                    <h4 className="mb-0">
                      Hotel/Motel <br /> Financing
                    </h4>
                  </div>
                  {/*/.card-body */}
                </div>
                {/*/.card */}
              </div>
              {/*/column */}
              <div className="col-md-6 col-xl-3">
                <div className="card shadow-lg lift h-100">
                  <div className="card-body bg-soft-dark border">
                    <h4 className="mb-0">
                      Commercial Real Estate Financing/ warehouse/ Office
                      Building
                    </h4>
                  </div>
                  {/*/.card-body */}
                </div>
                {/*/.card */}
              </div>
              {/*/column */}
              <div className="col-md-6 col-xl-3">
                <div className="card shadow-lg lift h-100">
                  <div className="card-body bg-soft-dark border">
                    <h4 className="mb-0">Gas Station</h4>
                  </div>
                  {/*/.card-body */}
                </div>
                {/*/.card */}
              </div>
              {/*/column */}
              <div className="col-md-6 col-xl-3">
                <div className="card shadow-lg lift h-100">
                  <div className="card-body bg-soft-dark border">
                    <h4 className="mb-0">Bridge Loan</h4>
                  </div>
                  {/*/.card-body */}
                </div>
                {/*/.card */}
              </div>
              {/*/column */}
            </div>
            {/*/.row */}
          </div>
          {/* /.position-relative */}
        </div>
        {/* /.container */}
      </section>
      {/* /section */}
      <Fade>
        {" "}
        <section className="wrapper bg-light">
          <div className="container py-4">
            <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
              {/*/column */}
              <div className="col-lg-12 text-center">{/*/.row */}</div>
              {/*/column */}
            </div>
            {/*/.row */}
          </div>
          {/* /.container */}
        </section>
      </Fade>
      <section className="wrapper bg-light">
        <div className="container py-4">
          <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-12 text-center d-none">
              <h1>Guru Bridge Loan Program</h1>
            </div>
            <div className="col-lg-6 d-none">
              <h3>Review of the bridge loan program</h3>
              <p className="mb-6">
                It is also known as interim financing, gap financing, or swing
                loans. It bridges the gap during times when long term financing
                is needed but not yet available. Both corporations and
                individuals use bridge loans. Lenders customize these loans for
                many different situations.
              </p>
              <h3>Purpose: Acquisition or refinance</h3>
              <p className="mb-6">
                The program is used for the purchase or refinance of commercial
                properties. The purpose of the refinance maybe to achieve cash
                out, partner buyout, transfer of ownership, PIP and renovations,
                etc.
              </p>

              {/*/.row */}
            </div>
            {/*/column */}
            <div className="col-lg-6 position-relative d-none">
              <div className="row gx-md-5 gy-5 position-relative">
                <div className="col-12">
                  <img
                    className="img-fluid rounded shadow-lg mb-5"
                    src="./assets/img/birdge-loan.png"
                    srcSet="./assets/img/birdge-loan.png"
                    alt=""
                  />
                </div>
              </div>
              {/* /.row */}
            </div>
            {/*/column */}
            <div className="col-lg-6 position-relative d-none">
              <div className="row gx-md-5 gy-5 position-relative">
                <div className="col-12">
                  <img
                    className="img-fluid rounded shadow-lg mb-5"
                    src="./assets/img/birdge-loan-2.png"
                    srcSet="./assets/img/bridge-loan-2.png"
                    alt=""
                  />
                </div>
              </div>
              {/* /.row */}
            </div>
            {/*/column */}
            <div className="col-lg-6 d-none">
              <h3>Rate: Range from 8% to 13%</h3>
              <p className="mb-6">
                The rate will depend on the project and the source we can place
                for that project. By the project we refer to the market the
                property is located in, the financials and the characteristics
                of the property such as age, brand, etc., and the strength of
                the borrower
              </p>
              <h3>Loan size: 1 million plus</h3>
              <p className="mb-6">
                This is the minimum loan Guru Financial Corp accepts for bridge
                loans
              </p>
              <h3>Loan to Value: up to 70%</h3>
              <p className="mb-6">
                The LTV ranges from 60% to 70% depending on our bridge source
                and the project
              </p>

              {/*/.row */}
            </div>
            {/*/column */}
            <div className="col-lg-6 d-none">
              <h3>Debt Service Coverage Ratio (DSCR): Project dependent</h3>
              <p className="mb-6">
                On many bridge loans, the loan is to help the borrowers to turn
                around the cash flow of the property. As such, in many projects,
                the DSCR is low and this is why a bridge loan is required. In
                many other instances, the cash flow is acceptable but the loan
                or the borrower is not qualified for a bank loan in which cases,
                the DSCR may be 1.3 and above
              </p>
              <h3>Reserves: None</h3>
              <p className="mb-6">
                This loan does not require FF&amp;E reserves and the lender does
                not escrow taxes and insurance
              </p>
              <h3>Initial Deposit:  Depends on our bridge sources</h3>
              <p className="mb-6">
                The amount and the purpose of the deposit depends on the source
                we use . This will be for third party work only
              </p>

              {/*/.row */}
            </div>
            {/*/column */}
            <div className="col-lg-6 text-center position-relative d-none">
              <div className="row gx-md-5 gy-5 position-relative">
                <div className="col-12">
                  <img
                    className="img-fluid rounded shadow-lg mb-5"
                    src="./assets/img/birdge-load-3.png"
                    srcSet="./assets/img/bridge-load-3.png"
                    alt=""
                  />
                </div>
              </div>
              {/* /.row */}
            </div>
            <div className="col-lg-6 text-center position-relative d-none">
              <div className="row gx-md-5 gy-5 position-relative">
                <div className="col-12">
                  <img
                    className="img-fluid rounded shadow-lg mb-5"
                    src="./assets/img/loan-process.png"
                    srcSet="./assets/img/loan-process.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            {/*/column */}
            <div className="col-lg-6 position-relative d-none">
              <div className="row gx-md-5 gy-5 position-relative">
                <div className="col-12">
                  <img
                    className="img-fluid rounded shadow-lg mb-5"
                    src="./assets/img/bridge-loan-4.png"
                    srcSet="./assets/img/bridge-loan-4.png"
                    alt=""
                  />
                </div>
              </div>
              {/* /.row */}
            </div>
            {/*/column */}
            <div className="col-lg-6 d-none">
              <h3>Amortization and Maturity: Interest only – 1 to 3 years</h3>
              <p className="mb-6">
                Often borrowers require interest only for the term of the bridge
                loan and that is the market standard. Additionally, the term of
                the loan is generally 1 to 3 years with exceptional cases to be
                under a year
              </p>
              <h3>Prepayment:  Depends on the source</h3>
              <p className="mb-6">
                Generally the bridge loans require an exit point which is about
                2 to 4 points. However, different lending sources plan different
                point programs, some charge higher at the funding time and have
                no prepay, some charge points at funding and points at exit
              </p>
              <h3>Points:</h3>
              <p className="mb-6">
                Bridge loans costs approximately 1 to 4 points
              </p>
              <h3>Personal guarantee: Both recourse and non-recourse</h3>
              <p className="mb-6">
                Both programs exist depending on the project. LTV might change
                due to non-recourse.
              </p>

              {/*/.row */}
            </div>
            {/*/column */}
          </div>
          <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center py-4">
            <div className="col-12 text-center d-none">
              <h1>Guru Loan Processing</h1>
            </div>
            <div className="col-lg-6 d-none">
              <p className="mb-6">
                Guru Financial Corporation always make the loan process pretty
                simple and straightforward. Once you have completed our simple
                online inquiry form, then we contact you to discuss your
                requirements in details and inform you the options available for
                your loan requirements. After telephonic conversation, you are
                in a better position to decide about your Loan options. <br />
                <br />
                Guru Financial Corp. provides a quotation or number of
                quotations without it having any impact on your credit score.
                Any information you provide will be kept strictly confidential
                and it will only be used to source quotations or process your
                application. We will support you, how to furnish all the
                documents, we require you to complete your loan application. We
                are here to help you and ensure that you are taking out finance
                that is best for your individual circumstances.
                <br />
                <br />
                If you have any questions, we are available to reply back soon;
                for any inquiry regarding to your Loan, please contact us.
              </p>

              {/*/.row */}
            </div>
            {/*/column */}
            {/* <div className="col-lg-6 text-center position-relative">
              <div className="row gx-md-5 gy-5 position-relative">
                <div className="col-12">
                  <img
                    className="img-fluid rounded shadow-lg mb-5"
                    src="./assets/img/loan-process.png"
                    srcSet="./assets/img/loan-process.png"
                    alt=""
                  />
                </div>
              </div>
             
            </div> */}
          </div>
          <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center py-4">
            <div className="col-12 text-center d-none">
              <h1>Review of the bridge loan program</h1>
            </div>
            {/*/column */}
            <div className="col-lg-6 position-relative d-none">
              <div className="row gx-md-5 gy-5 position-relative">
                <div className="col-12">
                  <img
                    className="img-fluid rounded shadow-lg mb-5"
                    src="./assets/img/project.png"
                    srcSet="./assets/img/project.png"
                    alt=""
                  />
                </div>
              </div>
              {/* /.row */}
            </div>
            <div className="col-lg-6 d-none">
              <h3>Purpose: Acquisition or refinance</h3>

              <p className="mb-6">
                The program is used for the purchase or refinance of commercial
                properties. The purpose of the refinance maybe to achieve cash
                out, partner buyout, transfer of ownership, PIP and renovations,
                etc.
              </p>
              <h3>Rate: Range from 8% to 13%</h3>

              <p className="mb-6">
                The rate will depend on the project and the source we can place
                for that project. By the project we refer to the market the
                property is located in, the financials and the characteristics
                of the property such as age, brand, etc., and the strength of
                the borrower
              </p>
              <h3>Loan size: 3 million plus</h3>

              <p className="mb-6">
                This is the minimum loan Guru Financial Corp accepts for bridge
                loans
              </p>

              <p />

              {/*/.row */}
            </div>
            <div className="col-lg-6 d-none">
              <h3>Loan to Value: up to 70%</h3>
              <p className="mb-6">
                The LTV ranges from 60% to 70% depending on our bridge source
                and the project
              </p>
              <h3>Debt Service Coverage Ratio (DSCR): Project dependent</h3>
              <p className="mb-6">
                On many bridge loans, the loan is to help the borrowers to turn
                around the cash flow of the property. As such, in many projects,
                the DSCR is low and this is why a bridge loan is required. In
                many other instances, the cash flow is acceptable but the loan
                or the borrower is not qualified for a bank loan in which cases,
                the DSCR may be 1.3 and above
              </p>
              <h3>Reserves: None</h3>
              <p className="mb-6">
                This loan does not require FF&amp;E reserves and the lender does
                not escrow taxes and insurance
              </p>
              <h3>Initial Deposit:  Depends on our bridge sources</h3>
              <p className="mb-6">
                The amount and the purpose of the deposit depends on the source
                we use
              </p>

              {/*/.row */}
            </div>
            {/*/column */}
            <div className="col-lg-6 position-relative d-none">
              <div className="row gx-md-5 gy-5 position-relative">
                <div className="col-12">
                  <img
                    className="img-fluid rounded shadow-lg mb-5"
                    src="./assets/img/invest.png"
                    srcSet="./assets/img/invest.png"
                    alt=""
                  />
                </div>
              </div>
              {/* /.row */}
            </div>
            {/*/column */}
            <div className="col-lg-6 position-relative d-none">
              <div className="row gx-md-5 gy-5 position-relative">
                <div className="col-12">
                  <img
                    className="img-fluid rounded shadow-lg mb-5"
                    src="./assets/img/loans-2.png"
                    srcSet="./assets/img/loans-2.png"
                    alt=""
                  />
                </div>
              </div>
              {/* /.row */}
            </div>
            {/*/column */}
            <div className="col-lg-6 d-none">
              <h3>Amortization and Maturity: Interest only – 1 to 3 years</h3>
              <p className="mb-6">
                Often borrowers require interest only for the term of the bridge
                loan and that is the market standard. Additionally, the term of
                the loan is generally 1 to 3 years with exceptional cases to be
                under a year
              </p>
              <h3>Prepayment:  Depends on the source</h3>
              <p className="mb-6">
                Generally the bridge loans require an exit point which is about
                1 to 2 points. However, different lending sources plan different
                point programs, some charge higher at the funding time and have
                no prepay, some charge points at funding and points at exit
              </p>
              <h3>Points:</h3>
              <p className="mb-6">Bridge loans require 1 to 3 points</p>
              <h3>IPersonal guarantee: Both recourse and non-recourse</h3>
              <p className="mb-6">
                Both programs exist depending on the project. LTV might change
                due to non-recourse.
              </p>

              {/*/.row */}
            </div>
            {/*/column */}
            <div className="col-12 text-center d-none">
              <h1>Commercial Real Estate Loans</h1>
            </div>
            <div className="col-lg-6 d-none">
              <p className="mb-6">
                For your business, you need to acquire some kind of real estate
                in order to have a place to operate the business. To purchase
                real estate , we have a range of commercial real estate products
                available through our professional partners. We can arrange
                financing for following types of properties.
                <br />
                <br />
                * Office: Professional, Condominium, Medical, Dental,
                Veterinarian and Mixed- Use
                <br />
                <br />
                * Industrial: Land Development, Heavy and Light Manufacturing,
                Warehouses &amp; Flex/R&amp;D
                <br />
                <br />
                * Retail/Shopping Center: General
                <br />
                <br />* Special Use: Assisted Living Facilities, Senior Housing,
                Funeral Homes, Day Care Facilities, Gas Stations, Restaurants,
                Hotels/Motels, Auto Care, Carwash, Software Companies, Data
                Storage, Franchises and many more.
              </p>

              {/*/.row */}
            </div>
            {/*/column */}
            <div className="col-lg-6 position-relative d-none">
              <div className="row gx-md-5 gy-5 position-relative">
                <div className="col-12">
                  <img
                    className="img-fluid rounded shadow-lg mb-5"
                    src="./assets/img/property.png"
                    srcSet="./assets/img/property.png"
                    alt=""
                  />
                </div>
              </div>
              {/* /.row */}
            </div>
            {/*/column */}
            <div className="col-12 text-center d-none">
              <h1>SBA 7(a) Loan Features</h1>
            </div>
            <div className="col-lg-6 position-relative d-none">
              <div className="row gx-md-5 gy-5 position-relative">
                <div className="col-12">
                  <img
                    className="img-fluid rounded shadow-lg mb-5"
                    src="./assets/img/features.png"
                    srcSet="./assets/img/features.png"
                    alt=""
                  />
                </div>
              </div>
              {/* /.row */}
            </div>
            {/*/column */}
            <div className="col-lg-6 d-none">
              <p className="mb-6">
                We have a network of lenders to process SBA 7(a) loans for your
                business requirements. We have;
              </p>
              <p>
                Quick Pre-approval and Decision Process , Low Down Payment ,
                Long-Term Financing , Fixed Maturity
              </p>

              <h3>Product Information</h3>
              <p className="mb-0">
                Loan Amount <br />
                Up to $5 million
              </p>
              <p className="mb-0">
                Loan Terms Up to 10 years for business acquisition, working
                capital or equipment financing Up to 25 years for real estate
              </p>
              <p className="mb-0">Interest Rate Variable Rates Available</p>
              <p className="mb-0">Percent of Guaranty</p>
              <p className="mb-0">
                85% guaranty for loans of $150,000 or less 75% guaranty for
                loans greater than $150,000
              </p>
              <p className="mb-0">(Up to $3.75 million max guaranty)</p>
              <p className="mb-0">Give us a call to get started!</p>

              {/*/.row */}
            </div>
            {/*/column */}
            <div className="col-12 text-center d-none">
              <h1>SBA 504 Loan</h1>
            </div>
            <div className="col-lg-6 position-relative d-none">
              <div className="row gx-md-5 gy-5 position-relative">
                <div className="col-12 text-center">
                  <img
                    className="img-fluid rounded shadow-lg mb-5"
                    src="./assets/img/sba-2.png"
                    srcSet="./assets/img/sba-2.png"
                    alt=""
                  />
                </div>
              </div>
              {/* /.row */}
            </div>
            {/*/column */}
            <div className="col-lg-6 d-none">
              <p className="mb-6">
                SBA 504 Loan is a real time support for a business to avoid
                bankruptcy. It can help to pay off existing debts. It is the
                most appropriate financing solution for your business. Guru
                Financial Corp. helps to arrange the same.
              </p>
              <h3>Instant features</h3>
              <p>
                Quick Decision making Process , Fixed Rates Available , Low Down
                Payment , No Balloon Payment
              </p>
              <h3>Product Information</h3>
              <p>
                Under this program, Bank provides 1st Deed financing and the
                Certified Development Company (CDC) provides 2nd Deed financing
                for commercial property purchases.
              </p>
              <h3>Loan Amount</h3>
              <p>
                1st Deed Financing: Up to 50% of total financing package
                2nd Deed Financing: Up to 40% of total financing package ( SBA
                504 portion )
              </p>
              <h3>Loan Terms</h3>
              <p>
                1st Deed Financing: Up to 10 years &amp; 20 - 25 years
                amortization 2nd Deed Financing: Up to 20 years full
                amortization.
              </p>
              <h3>Loan Purpose</h3>
              <p>Real estate, heavy equipment and other fixed assets</p>

              {/*/.row */}
            </div>
            {/*/column */}
            <div className="col-12 text-center d-none">
              <h1>FINANCIAL PRODUCTS</h1>
            </div>
            <div className="col-lg-6 d-none">
              <p className="mb-6">
                Guru Financial Corporation provides many financial products to
                develop new businesses and enable clients to form corporations
                and business structures, help them to draft privacy policies and
                structuring commercial transactions.
              </p>
              <p>
                We help to generate deals for a real estate development company
                that focuses on multi-family residential projects, including
                real-estate market analysis.
              </p>
              <p>
                We are providing financing for small to medium size business. We
                are specialized in Small Business Administration (SBA) loans for
                Gas Stations, Hotels, Motels, Hospitals, locker &amp; storage
                services and other small businesses. We can provide financing
                nationwide.
              </p>
              <p>We are offering following financial products;</p>
              <ul>
                <li>Commercial loans</li>
                <li>Construction financing</li>
                <li>Investment property loans</li>
                <li>Owner-Occupied business loans</li>
                <li>Capital markets loans</li>
                <li>Documentary letter of credit</li>
                <li>Stand by letter of credit</li>
                <li>Bank Guarantee</li>
              </ul>

              {/*/.row */}
            </div>
            {/*/column */}
            <div className="col-lg-6 position-relative d-none">
              <div className="row gx-md-5 gy-5 position-relative">
                <div className="col-12 text-center">
                  <img
                    className="img-fluid rounded shadow-lg mb-5"
                    src="./assets/img/loan-3.png"
                    srcSet="./assets/img/loan-3.png"
                    alt=""
                  />
                </div>
              </div>
              {/* /.row */}
            </div>
            <div className="col-lg-12 text-center d-none">
              <img src="./assets/img/loan-4.png" alt="" className="img-fluid" />
            </div>
            {/*/column */}
          </div>
          {/*/.row */}
        </div>
        {/* /.container */}
      </section>
    </>
  );
};

export default Services;
