import React, { Component, Fragment } from 'react'
import {
    BrowserRouter,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import About from '../pages/About';
import Contact from '../pages/Contact';

import Home from '../pages/Home';
import Services from '../pages/Services';



class Router extends Component {
    render() {
        return (
            <Fragment>
                 <Switch>
                <Route exact path="/" component={Home}/>
                <Route exact path="/about-us" component={About}/>
                <Route exact path="/services" component={Services}/>
                <Route exact path="/contact-us" component={Contact}/>
               
                </Switch>
            </Fragment>
        )
    }
}

export default Router
