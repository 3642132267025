class AppUrl {

    // static baseURL = 'https://rapi.com/api'
    static baseURL = 'https://company.smkhizer.com/public/api'
    static customURL = 'https://company.smkhizer.com/public'

    static home = this.baseURL+'/home'



    static portfolio_detail = this.baseURL+'/portfolio/'
    static portfolios = this.baseURL+'/portfolios'
    static services = this.baseURL+'/services'
    static about = this.baseURL+'/about'
    static reviews = this.baseURL+'/reviews'
    static skills = this.baseURL+'/skills'

    static pages = this.baseURL+'/pages'
    static footer = this.baseURL+'/footer'


    static contact_submit = this.baseURL+'/contact-submit'

    static charts = this.baseURL+'/charts'

}

export default AppUrl
