import React, { Component, Fragment , useState} from 'react'
import {
    NavLink
  } from "react-router-dom";

  import Logo from '../../logo.png'

const Navbar = () => {

    const [show, setShow] = useState('')


    const toggleNavbar = () => {
        if(show == '') {
            setShow('show')
        } else {
            setShow('')
        }
    }


        return (
            <Fragment>
           

                <header className="wrapper bg-soft-primary">
                    <div className="bg-dark text-white fw-bold fs-15">
                        <div className="container py-2 d-md-flex flex-md-row">
                            <div className="d-flex flex-row align-items-center">
                            <div className="icon text-white fs-22 mt-1 me-2"> <i className="fas fa-map-marker-alt"></i></div>
                            <address className="mb-0">Guru Financial Corp.
                    3296 E. Guasti Rd, 
                    Suite 120 
                    Ontario,
                    CA 91761
                    USA</address>
                            </div>
                            <div className="d-flex flex-row align-items-center me-6 ms-auto">
                            <div className="icon text-white fs-22 mt-1 me-2"> <i className="fas fa-phone-alt"></i></div>
                            <p className="mb-0">
                            <a href="tel:+17609463060">+1 760 946 3060  (Office)</a>
                            </p>
                            </div>
                            <div className="d-flex flex-row align-items-center">
                            <div className="icon text-white fs-22 mt-1 me-2"> <i className="fas fa-envelope"></i></div>
                            <p className="mb-0"><a href="mailto:gurufin81@gmail.Com" className=" link-white hover">gurufin81@gmail.Com</a></p>
                            </div>
                        </div>
                        {/* /.container */}
                    </div>

                    <nav className="navbar navbar-expand-lg center-nav navbar-dark navbar-bg-dark pt-0">
                        <div className="container flex-lg-row flex-nowrap align-items-center py-2">
                        <div className="navbar-brand w-100">
                        <NavLink to="/">

                                <a>
                                <img src={Logo} alt="" />
                                </a>
                        </NavLink>
                        </div>
                        <div style={{ visibility: show == 'show'? 'visible': 'hidden'}} className={`navbar-collapse offcanvas offcanvas-nav offcanvas-start ${show}`}>
                            <div className="offcanvas-header d-lg-none d-xl-none">
                            <a href="/"><img src={Logo} srcSet="./assets/img/logo-light@2x.png 2x" alt="" /></a>
                            <button type="button" onClick={() => toggleNavbar()} className="btn-close-white" data-bs-dismiss="offcanvas"><i className="fas fa-times"></i></button>
                   
                            </div>
                            <div className="offcanvas-body ms-lg-auto d-flex flex-column h-100">
                            <ul className="navbar-nav">
                                <li className="nav-item ">
                                <NavLink to="/">
                                    <a className="nav-link " >Home</a>
                                </NavLink>
                                
                                </li>
                                <li className="nav-item ">
                                <NavLink to="/about-us">
                                    <a className="nav-link " >About Us</a>
                                </NavLink>
                                
                                </li>
                                <li className="nav-item ">
                                <NavLink to="/services">
                                    <a className="nav-link " >Services</a>
                                </NavLink>
                                
                                </li>
                                <li className="nav-item ">
                                <NavLink to="/contact-us">
                                    <a className="nav-link " >Contact Us</a>
                                </NavLink>
                                
                                </li>
                            
                            </ul>
                            {/* /.navbar-nav */}
                            <div className="d-lg-none mt-auto pt-6 pb-6 order-4">
                            <a href="mailto:gurufincorp81@gmail.com" className=" link-white hover">gurufin81@gmail.com</a>
                                <br /> <a href="tel:+17609463060">+1 760 946 3060</a> <br />
                                <nav className="nav social social-white mt-4">
                                <a href="#"><i className="fab fa-twitter"></i></a>
                                <a href="#"><i className="fab fa-facebook"></i></a>
                                </nav>
                                {/* /.social */}
                            </div>
                            {/* /offcanvas-nav-other */}
                            </div>
                            {/* /.offcanvas-body */}
                        </div>
                        {/* /.navbar-collapse */}
                        <div className="navbar-other w-100 d-flex ms-auto">
                            <ul className="navbar-nav flex-row align-items-center ms-auto">
                            
                            <li className="nav-item d-none d-md-block">
                            <NavLink to="/contact-us">
                            <a className="btn btn-sm btn-primary rounded-pill">Contact</a>
                            </NavLink>
                                
                            </li>
                            <li className="nav-item d-lg-none">
                                <button className="hamburger offcanvas-nav-btn" onClick={() => toggleNavbar()}><span /></button>
                            </li>
                            </ul>
                            {/* /.navbar-nav */}
                        </div>
                        {/* /.navbar-other */}
                        </div>
                        {/* /.container */}
                    </nav>
                    {/* /.navbar */}
            </header>

            </Fragment>
        )
    }


export default Navbar
