import React, { Component, Fragment } from "react";

import { Helmet } from "react-helmet";
import Fade from "react-reveal/Fade";
import { NavLink } from "react-router-dom";

class Home extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }
  render() {
    return (
      <Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Trade Finance Solutions & Supply Chain Financing Support
          </title>
        </Helmet>

        <section className="wrapper bg-light">
          <div className="container pt-8 pt-md-14">
            <div className="row gx-lg-0 gx-xl-8 gy-10 gy-md-13 gy-lg-0 mb-7 mb-md-10 mb-lg-16 align-items-center">
              <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-1 position-relative order-lg-2">
                <figure className="rounded">
                  <img
                    src="/assets/img/photos/about7.jpg"
                    srcSet="/assets/img/photos/about7.jpg"
                    alt=""
                  />
                </figure>
              </div>
              {/*/column */}
              <div
                className="col-lg-5 mt-lg-n10 text-center text-lg-start"
                data-delay={600}
              >
                <h1 className="display-2 mb-5">
                  We are passionate about our business and our borrowers can see
                  it.
                </h1>
                <p className="lead fs-25 lh-sm mb-7 px-md-10 px-lg-0">
                  We are always available to answer any questions or to assist
                  our Borrowers at any time.
                </p>
                <div
                  className="d-flex justify-content-center justify-content-lg-start"
                  data-group="page-title-buttons"
                  data-delay={900}
                >
                  <span>
                    {/* <NavLink to="/services">
                      <a className="btn btn-lg btn-primary rounded-pill me-2">
                        Explore Now
                      </a>
                    </NavLink> */}
                  </span>
                  {/* <span><a to="#" className="btn btn-lg btn-outline-primary rounded-pill">Free Trial</a></span> */}
                </div>
              </div>
              {/*/column */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </section>

        <Fade>
          <section className="wrapper bg-light">
            <div className="container py-5 py-md-5">
              <div className="row gx-lg-8 gx-xl-12 gy-10  align-items-center">
                <div className="col-lg-6 position-relative">
                  <figure className="rounded mb-0">
                    <img
                      src="/assets/img/our-business.png"
                      srcSet="/assets/img/our-business.png"
                      alt=""
                    />
                  </figure>
                </div>
                {/*/column */}
                <div className="col-lg-6">
                  <h3 className="display-4 mb-4">Our Business</h3>
                  <p className="mb-5">
                    Guru Financial Corporation is in the financial services for
                    more than 23 years. Providing financial services to
                    importers and exporters of general products, commercial real
                    estate dealers or investors, also arranging project based
                    financing. We offer a broad portfolio of financial products
                    to meet almost any capital request from our clients. We
                    directly work with lenders and maintains mutual
                    relationships with banks, credit Unions, hedge funds, life
                    companies, and private investors.
                  </p>
                  {/* <NavLink to="/services">
                            <a className="btn btn-sm btn-primary rounded-pill mt-6 mb-0">
                            More Details
                            </a>
                        </NavLink> */}
                </div>
                {/*/column */}
              </div>
              {/*/.row */}
            </div>
            {/* /.container */}
          </section>
        </Fade>

        <Fade>
          <section className="wrapper bg-light">
            <div className="container py-14 py-md-16">
              <div className="row ">
                <div className="col-lg-8 col-xl-7 col-xxl-6">
                  <h3 className="display-4 mb-9">
                    Guru Financial Corporation is providing a variety of
                    services like;
                  </h3>
                </div>
                {/* /column */}
              </div>
              {/* /.row */}
              <div className="row gx-md-8 gy-8">
                <div className="col-md-6 col-lg-3">
                  <div className="icon btn btn-block btn-lg btn-soft-yellow disabled mb-5">
                    <i className="fas fa-file-invoice-dollar"></i>
                  </div>
                  <h4>Asset based Financing</h4>
                  {/* <NavLink to="/services">
                                    <a className="hover link-yellow">
                                    Learn More
                                </a>
                                </NavLink> */}
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="icon btn btn-block btn-lg btn-soft-red disabled mb-5">
                    <i className="fas fa-hand-holding-usd"></i>
                  </div>
                  <h4>Sale Leaseback</h4>
                  {/* <NavLink to="/services">
                                    <a className="hover link-red">
                                    Learn More
                                </a>
                                </NavLink> */}
                </div>
                {/*/column */}

                <div className="col-md-6 col-lg-3">
                  <div className="icon btn btn-block btn-lg btn-soft-leaf disabled mb-5">
                    <i className="fas fa-landmark"></i>
                  </div>
                  <h4>Factoring</h4>
                  {/* <NavLink to="/services">
                                    <a className="hover link-leaf">
                                    Learn More
                                </a>
                                </NavLink> */}
                </div>
                {/*/column */}
                <div className="col-md-6 col-lg-3">
                  <div className="icon btn btn-block btn-lg btn-soft-blue disabled mb-5">
                    <i className="fas fa-chart-line"></i>
                  </div>
                  <h4>Portfolio financing</h4>
                  {/* <NavLink to="/services">
                                    <a className="hover link-blue">
                                    Learn More
                                </a>
                                </NavLink> */}
                </div>
                {/*/column */}
                <div className="col-md-6 col-lg-3">
                  <div className="icon btn btn-block btn-lg btn-soft-navy disabled mb-5">
                    <i className="fas fa-hotel"></i>
                  </div>
                  <h4>Asset liability management</h4>
                  {/* <NavLink to="/services">
                                    <a className="hover link-navy">
                                    Learn More
                                </a>
                                </NavLink> */}
                </div>
                {/*/column */}
                <div className="col-md-6 col-lg-3">
                  <div className="icon btn btn-block btn-lg btn-soft-sky disabled mb-5">
                    <i className="fab fa-creative-commons"></i>
                  </div>
                  <h4>Construction Financing</h4>
                  {/* <NavLink to="/services">
                                    <a className="hover link-sky">
                                    Learn More
                                </a>
                                </NavLink> */}
                </div>
                {/*/column */}
                <div className="col-md-6 col-lg-3">
                  <div className="icon btn btn-block btn-lg btn-soft-pink disabled mb-5">
                    <i className="fas fa-file-invoice"></i>
                  </div>
                  <h4>Hotel/ Motel/Gas Station Financing</h4>
                  {/* <NavLink to="/services">
                                    <a className="hover link-pink">
                                    Learn More
                                </a>
                                </NavLink> */}
                </div>
                {/*/column */}
                <div className="col-md-6 col-lg-3">
                  <div className="icon btn btn-block btn-lg btn-soft-grape disabled mb-5">
                    <i className="fas fa-comments-dollar"></i>
                  </div>
                  <h4>Commercial Real Estate /Warehouse/ Office Building</h4>
                  {/* <NavLink to="/services">
                                    <a className="hover link-grape">
                                    Learn More
                                </a>
                                </NavLink> */}
                </div>
                {/*/column */}
                {/* <div className="col-md-6 col-lg-3">
                            <div className="icon btn btn-block btn-lg btn-soft-aqua disabled mb-5">
                            <i className="fas fa-bolt"></i>
                            </div>
                            <h4>Operational efficiency</h4>
                                <NavLink to="/services">
                                    <a className="hover link-aqua">
                                    Learn More
                                </a>
                                </NavLink>
                            
                            </div> */}
                {/*/column */}
                {/* <div className="col-md-6 col-lg-3">
                            <div className="icon btn btn-block btn-lg btn-soft-violet disabled mb-5">
                            <i className="fas fa-dollar-sign"></i>
                            </div>
                            <h4>Cost control</h4>
                                <NavLink to="/services">
                                    <a className="hover link-violet">
                                    Learn More
                                </a>
                                </NavLink>
                            
                            </div> */}
                {/*/column */}
              </div>
              {/*/.row */}
            </div>
            {/* /.container */}
          </section>
          {/* /section */}
        </Fade>

        <Fade>
          <section className="wrapper bg-light mb-md-10">
            <div className="container py-5 py-md-5">
              {/*/.row */}
              <div className="row gx-lg-8 gx-xl-12 gy-10  align-items-center">
                <div className="col-lg-6">
                  <h3 className="display-4 mb-4">Our Mission</h3>
                  <p className="mb-5">
                    Our mission is to provide highest level of customer service
                    and prove our efficiency while identifying the financial
                    needs of our clients and providing a long or short-term
                    solution to solve that capital needs. We always base our
                    success and reputation on our professional abilities and
                    bring the financial freedom for our clients. Trust upon our
                    credibility and contact us for all your financial needs, we
                    have a very simple process to follow for our valued clients.
                  </p>
                  {/* <NavLink to="/services">
                    <a className="btn btn-sm btn-primary rounded-pill mt-6 mb-0">
                      More Details
                    </a>
                  </NavLink> */}
                </div>
                {/*/column */}
                <div className="col-lg-6 position-relative">
                  <figure className="rounded mb-0">
                    <img
                      src="/assets/img/our-mission.png"
                      srcSet="/assets/img/our-mission.png"
                      alt=""
                    />
                  </figure>
                </div>
                {/*/column */}
              </div>
              {/*/.row */}
              {/*/.row */}
            </div>
            {/* /.container */}
          </section>
          {/* /section */}
        </Fade>
      </Fragment>
    );
  }
}

export default Home;
