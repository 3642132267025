import React, { useEffect } from "react";

import { Helmet } from "react-helmet";
import Fade from "react-reveal/Fade";

const About = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us - Gurufc</title>
      </Helmet>

      <section className="wrapper bg-soft-primary">
        <div className="container py-md-5 py-3 text-center">
          <div className="row">
            <div className="col-xl-7 mx-auto mb-6">
              <h1 className="display-1 mb-3">About Us</h1>
              <h3 className="mb-0">
                Guru Financial Corporation is a commercial financing brokerage
                company
              </h3>
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>

      <Fade>
        {" "}
        <section className="wrapper bg-light">
          <div className="container py-md-12  py-4">
            <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
              <div className="col-md-12 col-lg-6 col-xl-5 order-lg-2 position-relative">
                <figure className="rounded">
                  <img
                    src="./assets/img/about.png"
                    srcSet="./assets/img/about.png"
                    alt=""
                  />
                </figure>
              </div>
              {/*/column */}
              <div className="col-lg-6">
                <h2 className="display-4 mb-3">Who Are We?</h2>
                <p className="lead fs-lg">
                  GFC Started business in 1998 in Apple Valley, California - USA
                </p>
                <p className="mb-6">
                  We always focus on the life-time client relationship rather
                  than a single financing transaction. We practice strong
                  ability to analyze the projects and to find instant solutions.
                  Our determination and the full command on the subject, allow
                  us to perform our job efficiently.
                </p>

                {/*/.row */}
              </div>
              {/*/column */}
            </div>
            {/*/.row */}
          </div>
          {/* /.container */}
        </section>
      </Fade>
      <Fade>
        <section className="wrapper bg-light">
          <div className="container py-md-6 py-3">
            <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
              <div className="col-lg-6 position-relative">
                <div className="row gx-md-5 gy-5 position-relative">
                  <div className="col-12">
                    <img
                      className="img-fluid rounded shadow-lg mb-5"
                      src="./assets/img/loans.png"
                      srcSet="./assets/img/loans.png"
                      alt=""
                    />
                  </div>
                </div>
                {/* /.row */}
              </div>
              {/*/column */}
              <div className="col-lg-6">
                <h3 className="display-4 mb-5">
                  We arrange Commercial/SBA loans for
                </h3>
                <p className="mb-5">Acquisition including</p>
                <div className="row gy-3">
                  <div className="col-xl-6">
                    <ul className="icon-list bullet-bg bullet-soft-red mb-0">
                      <li>
                        <span>
                          <i className="fas fa-circle" />
                        </span>
                        <span>Refinance</span>
                      </li>
                      <li className="mt-3">
                        <span>
                          <i className="fas fa-circle" />
                        </span>
                        <span>Equity cashout</span>
                      </li>
                      <li className="mt-3">
                        <span>
                          <i className="fas fa-circle" />
                        </span>
                        <span>Debt consolidations</span>
                      </li>
                      <li className="mt-3">
                        <span>
                          <i className="fas fa-circle" />
                        </span>
                        <span>Construction/Take out</span>
                      </li>
                    </ul>
                  </div>
                  {/*/column */}
                  <div className="col-xl-6">
                    <ul className="icon-list bullet-bg bullet-soft-red mb-0">
                      <li>
                        <span>
                          <i className="fas fa-circle" />
                        </span>
                        <span>Partner buyout</span>
                      </li>
                      <li className="mt-3">
                        <span>
                          <i className="fas fa-circle" />
                        </span>
                        <span>Family transfers</span>
                      </li>
                      <li className="mt-3">
                        <span>
                          <i className="fas fa-circle" />
                        </span>
                        <span>Seller carry pay off</span>
                      </li>
                      <li className="mt-3">
                        <span>
                          <i className="fas fa-circle" />
                        </span>
                        <span>Renovations and expansions</span>
                      </li>
                    </ul>
                  </div>
                  {/*/column */}
                </div>
                {/*/.row */}
              </div>
              {/*/column */}
            </div>
            {/*/.row */}
          </div>
          {/* /.container */}
        </section>
        {/* /section */}
      </Fade>
      <Fade>
        {" "}
        <section className="wrapper bg-light">
          <div className="container py-md-6 py-3">
            <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
              <div className="col-lg-6 mt-0">
                <h3 className="display-4 mb-5">We finance</h3>
                {/* <p className="mb-5">Acquisition including</p> */}
                <div className="row gy-3">
                  <div className="col-xl-12">
                    <ul className="icon-list bullet-bg bullet-soft-red mb-0">
                      <li>
                        <span>
                          <i className="fas fa-circle" />
                        </span>
                        <span>Franchise Properties</span>
                      </li>
                      <li className="mt-3">
                        <span>
                          <i className="fas fa-circle" />
                        </span>
                        <span>Independent Properties</span>
                      </li>
                      {/* <li className="mt-3">
                        <span>
                          <i className="fas fa-circle" />
                        </span>
                        <span>Boutique hotels</span>
                      </li> */}
                    </ul>
                  </div>
                  <div className="col-12">
                    <h6 className="mt-5">
                      We cover all fifty states <br />
                      &amp; All the markets including rural tertiary markets
                    </h6>
                  </div>
                  {/*/column */}
                </div>
                {/*/.row */}
              </div>
              {/*/column */}
              <div className="col-lg-6 position-relative">
                <div className="row gx-md-5 gy-5 position-relative">
                  <div className="col-12">
                    <img
                      className="img-fluid rounded shadow-lg mb-5"
                      src="./assets/img/loans-2.png"
                      srcSet="./assets/img/loans-2.png"
                      alt=""
                    />
                  </div>
                </div>
                {/* /.row */}
              </div>
              {/*/column */}
            </div>
            {/*/.row */}
          </div>
          {/* /.container */}
        </section>
        {/* /section */}
      </Fade>
    </>
  );
};

export default About;
